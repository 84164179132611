import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './locales';
import { supportedLanguages } from './supported-languages';

const fallbackLanguage = ['es'];

const options: InitOptions = {
  react: {
    useSuspense: false,
  },
  resources,
  fallbackLng: fallbackLanguage,
  debug: false,
  supportedLngs: supportedLanguages,
  interpolation: {
    escapeValue: false, // no need for react. it escapes by default
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init(options);
i18n.changeLanguage('es'); // Temporary force spanish language

export default i18n;
