interface UseTemplateDownloadParams {
  countryId: string;
  templatesByCountry: {
    CL: string;
    MX: string;
  };
}

interface UseTemplateDownloadResponse {
  onTemplateDownload: () => void;
}

const useTemplateDownload = ({
  countryId,
  templatesByCountry,
}: UseTemplateDownloadParams): UseTemplateDownloadResponse => {
  const onTemplateDownload = () => {
    const link = document.createElement('a');
    link.download = `carga-masiva.csv`;
    link.href = `${templatesByCountry[countryId]}?t=${Date.now()}`;
    link.click();
  };

  return {
    onTemplateDownload,
  };
};

export { useTemplateDownload };
