import { PURGE } from 'redux-persist';
import {
  CONTACT_DETAIL_CLEANUP,
  CONTACT_DETAIL_RESET,
  CREATE_CONTACT_DETAIL_FAILURE,
  CREATE_CONTACT_DETAIL_START,
  CREATE_CONTACT_DETAIL_SUCCESS,
  DELETE_CONTACT_DETAIL_FAILURE,
  DELETE_CONTACT_DETAIL_START,
  DELETE_CONTACT_DETAIL_SUCCESS,
  FETCH_CONTACT_DETAIL_FAILURE,
  FETCH_CONTACT_DETAIL_START,
  FETCH_CONTACT_DETAIL_SUCCESS,
  LOCAL_PURGE,
  UPDATE_CONTACT_DETAIL_FAILURE,
  UPDATE_CONTACT_DETAIL_START,
  UPDATE_CONTACT_DETAIL_SUCCESS,
} from '../actions/types';

import { t13s } from '../../config/translationKeys';

const initialState = {
  supplierContactDetailCreated: false,
  supplierContactDetails: [],
  supplierContactDetailsError: null,
  supplierContactDetailUpdated: false,
  supplierCreateContactDetailError: null,
  supplierCreatingContactDetail: false,
  supplierDeleteContactDetailError: null,
  supplierDeletingContactDetail: false,
  supplierFetchContactDetailError: null,
  supplierFetchingContactDetails: false,
  supplierUpdateContactDetailError: null,
  supplierUpdatingContactDetail: false,
};

const fetchContactDetailsStart = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}FetchContactDetailError`]: null,
    [`${contactableType}FetchingContactDetails`]: true,
  };
};

const fetchContactDetailsSuccess = (state, payload) => {
  const { contactDetails, contactableType } = payload;
  return {
    ...state,
    [`${contactableType}FetchingContactDetails`]: false,
    [`${contactableType}ContactDetails`]: contactDetails,
  };
};

const fetchContactDetailsFailure = (state, payload) => {
  const { errorCode, contactableType } = payload;
  return {
    ...state,
    [`${contactableType}FetchingContactDetails`]: false,
    [`${contactableType}FetchContactDetailError`]:
      errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const createContactDetailStart = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}ContactDetailCreated`]: false,
    [`${contactableType}CreateContactDetailError`]: null,
    [`${contactableType}CreatingContactDetail`]: true,
  };
};

const createContactDetailSuccess = (state, payload) => {
  const { contactableType } = payload;

  const { [`${contactableType}ContactDetails`]: stateContactDetails } = state;
  return {
    ...state,
    [`${contactableType}ContactDetailCreated`]: true,
    [`${contactableType}CreatingContactDetail`]: false,
    [`${contactableType}ContactDetails`]: [...stateContactDetails, payload],
  };
};

const createContactDetailFailure = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}CreatingContactDetail`]: false,
    [`${contactableType}CreateContactDetailError`]:
      payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const updateContactDetailStart = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}ContactDetailUpdated`]: false,
    [`${contactableType}UpdateContactDetailError`]: null,
    [`${contactableType}UpdatingContactDetail`]: true,
  };
};

const updateContactDetailSuccess = (state, payload) => {
  const { contactableType } = payload;
  const { [`${contactableType}ContactDetails`]: stateContactDetails } = state;

  const contactDetails = stateContactDetails.filter(
    contactDetail => contactDetail.id !== payload.id
  );

  contactDetails.push(payload);

  return {
    ...state,
    [`${contactableType}ContactDetailUpdated`]: true,
    [`${contactableType}UpdatingContactDetail`]: false,
    [`${contactableType}ContactDetails`]: contactDetails,
  };
};

const updateContactDetailFailure = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}UpdatingContactDetail`]: false,
    [`${contactableType}UpdateContactDetailError`]:
      payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const deleteContactDetailStart = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}DeletingContactDetail`]: true,
    [`${contactableType}DeleteContactDetailError`]: null,
  };
};

const deleteContactDetailSuccess = (state, payload) => {
  const { contactDetailId, contactableType } = payload;
  const { [`${contactableType}ContactDetails`]: stateContactDetails } = state;

  const updatedContactDetails = stateContactDetails.filter(
    contact => contact.id !== contactDetailId
  );
  return {
    ...state,
    [`${contactableType}DeletingContactDetail`]: false,
    [`${contactableType}DeleteContactDetailError`]: null,
    [`${contactableType}ContactDetails`]: updatedContactDetails,
  };
};

const deleteContactDetailFailure = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}DeletingContactDetail`]: false,
    [`${contactableType}DeleteContactDetailError`]:
      payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const contactDetailReset = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}CreatingContactDetail`]: false,
    [`${contactableType}CreateContactDetailError`]: null,
    [`${contactableType}ContactDetailCreated`]: false,
    [`${contactableType}UpdatingContactDetail`]: false,
    [`${contactableType}UpdateContactDetailError`]: null,
    [`${contactableType}ContactDetailUpdated`]: false,
  };
};

const contactDetailCleanup = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}ContactDetails`]: [],
    [`${contactableType}CreatingContactDetail`]: false,
    [`${contactableType}CreateContactDetailError`]: null,
    [`${contactableType}ContactDetailCreated`]: false,
    [`${contactableType}UpdatingContactDetail`]: false,
    [`${contactableType}UpdateContactDetailError`]: null,
    [`${contactableType}ContactDetailUpdated`]: false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CONTACT_DETAIL_START:
      return fetchContactDetailsStart(state, payload);
    case FETCH_CONTACT_DETAIL_SUCCESS:
      return fetchContactDetailsSuccess(state, payload);
    case FETCH_CONTACT_DETAIL_FAILURE:
      return fetchContactDetailsFailure(state, payload);
    case CREATE_CONTACT_DETAIL_START:
      return createContactDetailStart(state, payload);
    case CREATE_CONTACT_DETAIL_SUCCESS:
      return createContactDetailSuccess(state, payload);
    case CREATE_CONTACT_DETAIL_FAILURE:
      return createContactDetailFailure(state, payload);
    case UPDATE_CONTACT_DETAIL_START:
      return updateContactDetailStart(state, payload);
    case UPDATE_CONTACT_DETAIL_SUCCESS:
      return updateContactDetailSuccess(state, payload);
    case UPDATE_CONTACT_DETAIL_FAILURE:
      return updateContactDetailFailure(state, payload);
    case DELETE_CONTACT_DETAIL_START:
      return deleteContactDetailStart(state, payload);
    case DELETE_CONTACT_DETAIL_SUCCESS:
      return deleteContactDetailSuccess(state, payload);
    case DELETE_CONTACT_DETAIL_FAILURE:
      return deleteContactDetailFailure(state, payload);
    case CONTACT_DETAIL_RESET:
      return contactDetailReset(state, payload);
    case CONTACT_DETAIL_CLEANUP:
      return contactDetailCleanup(state, payload);

    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
