import copy from 'copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useBusiness } from '@payments/hooks';
import { useTranslation } from '../../../../../features/i18n/infra/useTranslation';
import { enqueueSnackbar } from '../../../../store/actions/notificationActions';
import { t13s } from '../../../../config/translationKeys';
import useWallet from '../useWallet';

const useCashInInfoModal = amountToPay => {
  const { countryId } = useBusiness();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { wallet } = useWallet();

  const accountNumber = wallet?.accountNumber || '-';
  const bankName = wallet?.bankName || '-';

  const { name: businessName } = useSelector(
    (state: { authState: any }) => state.authState.business
  );

  const copyAccountNumberToClipboard = () => {
    copy(accountNumber, { format: 'text/plain' });
    dispatch(
      enqueueSnackbar({
        message: `${t(
          t13s.LABEL.BANK_ACCOUNT_IDENTIFIER
        )} copiada al portapapeles`,
        options: {
          variant: 'success',
        },
      })
    );
  };

  const description = amountToPay
    ? 'Transfiere a la siguiente cuenta el monto que necesites para recargar tu saldo'
    : '';
  const title = amountToPay ? 'Recargar saldo' : 'Depositar';

  const items: { label; value?; amount? }[] = [
    {
      label: t(t13s.LABEL.BANK_ACCOUNT_IDENTIFIER),
      value: accountNumber,
    },
    {
      label: 'Nombre',
      value: businessName,
    },
    {
      label: 'Banco',
      value: bankName,
    },
  ];

  if (amountToPay) {
    items.unshift({ label: 'Total a pagar', amount: amountToPay });
  }

  return {
    countryId,
    title,
    description,
    items,
    copyAccountNumberToClipboard,
  };
};

export default useCashInInfoModal;
