export const LOCAL_PURGE = 'LOCAL_PURGE';

// UI STATES
export const SET_LOCATION = 'SET_LOCATION';
export const WAS_IN = 'WAS_IN';
export const SET_UTM_INFO = 'SET_UTM_INFO';
export const SET_REFERRAL_CODE = 'SET_REFERRAL_CODE';
export const SET_SUDO_USER = 'SET_SUDO_USER';
export const SET_SHOW_TOUR_POST_REGISTER = 'SET_SHOW_TOUR_POST_REGISTER';

// WS
export const WS_CONNECT_MIDDLEWARE = 'WS_CONNECT_MIDDLEWARE';
export const WS_RECONNECT_MIDDLEWARE = 'WS_RECONNECT_MIDDLEWARE';
export const WS_DISCONNECT_MIDDLEWARE = 'WS_DISCONNECT_MIDDLEWARE';
export const WS_CREATE_CONNECTION_MIDDLEWARE =
  'WS_CREATE_CONNECTION_MIDDLEWARE';
export const WS_ADD_MESSAGE = 'WS_ADD_MESSAGE';
export const WS_REMOVE_FIRST_MESSAGE = 'WS_REMOVE_FIRST_MESSAGE';

export const WS_CONNECTED_STORE = 'WS_CONNECTED_STORE';
export const WS_DISCONNECTED_STORE = 'WS_DISCONNECTED_STORE';
export const WS_TAX_SCRAPE_UPDATED_STORE = 'WS_TAX_SCRAPE_UPDATED_STORE';
export const WS_BUSINESS_NOTIFIED_STORE = 'WS_BUSINESS_NOTIFIED_STORE';

// NOTIFICACIONES
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

// AUTH
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const REGISTER_USER_START = 'REGISTER_USER_START';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const COMPLETE_REGISTER_START = 'COMPLETE_REGISTER_START';
export const COMPLETE_REGISTER_SUCCESS = 'COMPLETE_REGISTER_SUCCESS';
export const COMPLETE_REGISTER_ERROR = 'COMPLETE_REGISTER_ERROR';
export const VERIFY_EMAIL_START = 'VERIFY_EMAIL_START';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';
export const VERIFY_EMAIL_RESET = 'VERIFY_EMAIL_RESET';
export const FETCH_ENTITY_FEATURE_START = 'FETCH_ENTITY_FEATURE_START';
export const FETCH_ENTITY_FEATURE_SUCCESS = 'FETCH_ENTITY_FEATURE_SUCCESS';
export const FETCH_ENTITY_FEATURE_ERROR = 'FETCH_ENTITY_FEATURE_ERROR';

// CARDS
export const FETCH_CARDS_INFO_START = 'FETCH_CARDS_INFO_START';
export const FETCH_CARDS_INFO_ERROR = 'FETCH_CARDS_INFO_ERROR';
export const FETCH_CARDS_INFO_SUCCESS = 'FETCH_CARDS_INFO_SUCCESS';

// BUSINESS
export const FETCH_SETTINGS_RESUME_START = 'FETCH_SETTINGS_RESUME_START';
export const FETCH_SETTINGS_RESUME_ERROR = 'FETCH_SETTINGS_RESUME_ERROR';
export const FETCH_SETTINGS_RESUME_SUCCESS = 'FETCH_SETTINGS_RESUME_SUCCESS';
export const NAME_BY_IDENTIFIER_START = 'NAME_BY_IDENTIFIER_START';
export const NAME_BY_IDENTIFIER_SUCCESS = 'NAME_BY_IDENTIFIER_SUCCESS';
export const NAME_BY_IDENTIFIER_ERROR = 'NAME_BY_IDENTIFIER_ERROR';
export const NAME_BY_IDENTIFIER_RESET = 'NAME_BY_IDENTIFIER_RESET';
export const FETCH_BUSINESS_START = 'FETCH_BUSINESS_START';
export const FETCH_BUSINESS_SUCCESS = 'FETCH_BUSINESS_SUCCESS';
export const FETCH_BUSINESS_ERROR = 'FETCH_BUSINESS_ERROR';
export const UPDATE_BUSINESS_START = 'UPDATE_BUSINESS_START';
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS';
export const UPDATE_BUSINESS_ERROR = 'UPDATE_BUSINESS_ERROR';
export const FETCH_INVOICE_DOCUMENTS_START = 'FETCH_INVOICE_DOCUMENTS_START';
export const FETCH_INVOICE_DOCUMENTS_SUCCESS =
  'FETCH_INVOICE_DOCUMENTS_SUCCESS';
export const FETCH_INVOICE_DOCUMENTS_ERROR = 'FETCH_INVOICE_DOCUMENTS_ERROR';
export const DOWNLOAD_INVOICE_DOCUMENTS_START =
  'DOWNLOAD_INVOICE_DOCUMENTS_START';
export const DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS =
  'DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS';
export const DOWNLOAD_INVOICE_DOCUMENTS_ERROR =
  'DOWNLOAD_INVOICE_DOCUMENTS_ERROR';
export const FETCH_TGR_DEBT_START = 'FETCH_TGR_DEBT_START';
export const FETCH_TGR_DEBT_SUCCESS = 'FETCH_TGR_DEBT_SUCCESS';
export const FETCH_TGR_DEBT_ERROR = 'FETCH_TGR_DEBT_ERROR';

// BUSINESS BANK ACCOUNTS
export const FETCH_BUSINESS_BANK_ACCOUNTS_START =
  'FETCH_BUSINESS_BANK_ACCOUNTS_START';
export const FETCH_BUSINESS_BANK_ACCOUNTS_SUCCESS =
  'FETCH_BUSINESS_BANK_ACCOUNTS_SUCCESS';
export const FETCH_BUSINESS_BANK_ACCOUNTS_FAILURE =
  'FETCH_BUSINESS_BANK_ACCOUNTS_FAILURE';
export const CREATE_BUSINESS_BANK_ACCOUNT_START =
  'CREATE_BUSINESS_BANK_ACCOUNT_START';
export const CREATE_BUSINESS_BANK_ACCOUNT_SUCCESS =
  'CREATE_BUSINESS_BANK_ACCOUNT_SUCCESS';
export const CREATE_BUSINESS_BANK_ACCOUNT_FAILURE =
  'CREATE_BUSINESS_BANK_ACCOUNT_FAILURE';
export const UPDATE_BUSINESS_BANK_ACCOUNT_START =
  'UPDATE_BUSINESS_BANK_ACCOUNT_START';
export const UPDATE_BUSINESS_BANK_ACCOUNT_SUCCESS =
  'UPDATE_BUSINESS_BANK_ACCOUNT_SUCCESS';
export const UPDATE_BUSINESS_BANK_ACCOUNT_FAILURE =
  'UPDATE_BUSINESS_BANK_ACCOUNT_FAILURE';
export const SET_DEFAULT_BANK_ACCOUNT_START = 'SET_DEFAULT_BANK_ACCOUNT_START';
export const SET_DEFAULT_BANK_ACCOUNT_SUCCESS =
  'SET_DEFAULT_BANK_ACCOUNT_SUCCESS';
export const SET_DEFAULT_BANK_ACCOUNT_FAILURE =
  'SET_DEFAULT_BANK_ACCOUNT_FAILURE';
export const DELETE_BANK_ACCOUNT_START = 'DELETE_BANK_ACCOUNT_START';
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS';
export const DELETE_BANK_ACCOUNT_FAILURE = 'DELETE_BANK_ACCOUNT_FAILURE';
export const BANK_ACCOUNT_RESET = 'BANK_ACCOUNT_RESET';
export const GET_ACCOUNT_VALIDATION_STATUS_START =
  'GET_ACCOUNT_VALIDATION_STATUS_START';
export const GET_ACCOUNT_VALIDATION_STATUS_SUCCESS =
  'GET_ACCOUNT_VALIDATION_STATUS_SUCCESS';
export const GET_ACCOUNT_VALIDATION_STATUS_FAILURE =
  'GET_ACCOUNT_VALIDATION_STATUS_FAILURE';

// TAX_SERVICE
export const CHECK_TAX_SERVICE_CREDENTIAL_START =
  'CHECK_TAX_SERVICE_CREDENTIAL_START';
export const CHECK_TAX_SERVICE_CREDENTIAL_SUCCESS =
  'CHECK_TAX_SERVICE_CREDENTIAL_SUCCESS';
export const CHECK_TAX_SERVICE_CREDENTIAL_ERROR =
  'CHECK_TAX_SERVICE_CREDENTIAL_ERROR';
export const CREATE_TAX_SERVICE_CREDENTIAL_START =
  'CREATE_TAX_SERVICE_CREDENTIAL_START';
export const CREATE_TAX_SERVICE_CREDENTIAL_SUCCESS =
  'CREATE_TAX_SERVICE_CREDENTIAL_SUCCESS';
export const CREATE_TAX_SERVICE_CREDENTIAL_ERROR =
  'CREATE_TAX_SERVICE_CREDENTIAL_ERROR';
export const CREATE_TAX_SERVICE_CREDENTIAL_RESET =
  'CREATE_TAX_SERVICE_CREDENTIAL_RESET';

// CART
export const FETCH_CART_INVOICES_SUCCESS = 'FETCH_CART_INVOICES_SUCCESS';
export const FETCH_CART_INVOICES_ERROR = 'FETCH_CART_INVOICES_ERROR';
export const FETCH_CART_INVOICES_START = 'FETCH_CART_INVOICES_START';
export const FETCH_CART_INVOICES_COUNT_SUCCESS =
  'FETCH_CART_INVOICES_COUNT_SUCCESS';
export const FETCH_CART_INVOICES_COUNT_ERROR =
  'FETCH_CART_INVOICES_COUNT_ERROR';
export const FETCH_CART_INVOICES_COUNT_START =
  'FETCH_CART_INVOICES_COUNT_START';
export const HANDLE_INVOICES_SELECTION_CHANGE =
  'HANDLE_INVOICES_SELECTION_CHANGE';
export const FETCH_CART_INVOICES_TOTAL_AMOUNT_START =
  'FETCH_CART_INVOICES_TOTAL_AMOUNT_START';
export const FETCH_CART_INVOICES_TOTAL_AMOUNT_SUCCESS =
  'FETCH_CART_INVOICES_TOTAL_AMOUNT_SUCCESS';
export const FETCH_CART_INVOICES_TOTAL_AMOUNT_ERROR =
  'FETCH_CART_INVOICES_TOTAL_AMOUNT_ERROR';
export const RESET_CART_INVOICES = 'RESET_CART_INVOICES';
export const RESET_SELECTED_INVOICES = 'RESET_SELECTED_INVOICES';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const HANDLE_INVOICES_DATE_CHANGE = 'HANDLE_INVOICES_DATE_CHANGE';
export const CART_SIMULATE_START = 'CART_SIMULATE_START';
export const CART_SIMULATE_SUCCESS = 'CART_SIMULATE_SUCCESS';
export const SET_IS_FIRST_SIMULATION = 'SET_IS_FIRST_SIMULATION';
export const CART_SIMULATE_ERROR = 'CART_SIMULATE_ERROR';
export const ORDER_SUBMIT_START = 'ORDER_SUBMIT_START';
export const ORDER_SUBMIT_SUCCESS = 'ORDER_SUBMIT_SUCCESS';
export const ORDER_SUBMIT_ERROR = 'ORDER_SUBMIT_ERROR';

// TASKS
export const FETCH_TASKS_START = 'FETCH_TASKS_START';
export const FETCH_TASKS_ERROR = 'FETCH_TASKS_ERROR';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';

// HOLIDAYS
export const FETCH_HOLIDAYS_START = 'FETCH_HOLIDAYS_START';
export const FETCH_HOLIDAYS_SUCCESS = 'FETCH_HOLIDAYS_SUCCESS';
export const FETCH_HOLIDAYS_ERROR = 'FETCH_HOLIDAYS_ERROR';

// ORDER
export const FETCH_ORDER_DETAIL_START = 'FETCH_ORDER_DETAIL_START';
export const FETCH_ORDER_DETAIL_SUCCESS = 'FETCH_ORDER_DETAIL_SUCCESS';
export const FETCH_ORDER_DETAIL_ERROR = 'FETCH_ORDER_DETAIL_ERROR';
export const FETCH_ORDER_INVOICES_START = 'FETCH_ORDER_INVOICES_START';
export const FETCH_ORDER_INVOICES_SUCCESS = 'FETCH_ORDER_INVOICES_SUCCESS';
export const FETCH_ORDER_INVOICES_ERROR = 'FETCH_ORDERINVOICES_ERROR';
export const RESET_ORDER_DETAILS = 'RESET_ORDER_DETAILS';
export const DOWNLOAD_ORDER_INVOICE_SIMULATION_START =
  'DOWNLOAD_ORDER_INVOICE_SIMULATION_START';
export const DOWNLOAD_ORDER_INVOICE_SIMULATION_SUCCESS =
  'DOWNLOAD_ORDER_INVOICE_SIMULATION_SUCCESS';
export const DOWNLOAD_ORDER_INVOICE_SIMULATION_FAILURE =
  'DOWNLOAD_ORDER_INVOICE_SIMULATION_FAILURE';
export const FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_START =
  'FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_START';
export const FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_SUCCESS =
  'FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_SUCCESS';
export const FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_FAILURE =
  'FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_FAILURE';
export const RESET_PDF_WAS_UPLOADED_STATE = 'RESET_PDF_WAS_UPLOADED_STATE';

export const UPLOAD_ORDER_INVOICE_PDF_START = 'UPLOAD_ORDER_INVOICE_PDF_START';
export const UPLOAD_ORDER_INVOICE_PDF_SUCCESS =
  'UPLOAD_ORDER_INVOICE_PDF_SUCCESS';
export const UPLOAD_ORDER_INVOICE_PDF_FAILURE =
  'UPLOAD_ORDER_INVOICE_PDF_FAILURE';
export const DELETE_ORDER_INVOICE_PDF_START = 'DELETE_ORDER_INVOICE_PDF_START';
export const DELETE_ORDER_INVOICE_PDF_SUCCESS =
  'DELETE_ORDER_INVOICE_PDF_SUCCESS';
export const DELETE_ORDER_INVOICE_PDF_FAILURE =
  'DELETE_ORDER_INVOICE_PDF_FAILURE';
// ORDERS
export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR';

// USER
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET';

// STAKEHOLDERS
export const FETCH_STAKEHOLDERS_START = 'FETCH_STAKEHOLDERS_START';
export const FETCH_STAKEHOLDERS_ERROR = 'FETCH_STAKEHOLDERS_ERROR';
export const FETCH_STAKEHOLDERS_SUCCESS = 'FETCH_STAKEHOLDERS_SUCCESS';
export const EDIT_STAKEHOLDERS_START = 'EDIT_STAKEHOLDERS_START';
export const EDIT_STAKEHOLDERS_ERROR = 'EDIT_STAKEHOLDERS_ERROR';
export const EDIT_STAKEHOLDERS_SUCCESS = 'EDIT_STAKEHOLDERS_SUCCESS';
export const CREATE_STAKEHOLDERS_START = 'CREATE_STAKEHOLDERS_START';
export const CREATE_STAKEHOLDERS_SUCCESS = 'CREATE_STAKEHOLDERS_SUCCESS';
export const CREATE_STAKEHOLDERS_ERROR = 'CREATE_STAKEHOLDERS_ERROR';
export const RESET_STAKEHOLDERS_ERRORS = 'RESET_STAKEHOLDERS_ERRORS';

// BUSINESS DOCUMENTS
export const FETCH_BUSINESS_DOCUMENT_START = 'FETCH_BUSINESS_DOCUMENT_START';
export const FETCH_BUSINESS_DOCUMENT_SUCCESS =
  'FETCH_BUSINESS_DOCUMENT_SUCCESS';
export const FETCH_BUSINESS_DOCUMENT_ERROR = 'FETCH_BUSINESS_DOCUMENT_ERROR';
export const UPLOAD_BUSINESS_DOCUMENT_START = 'UPLOAD_BUSINESS_DOCUMENT_START';
export const UPLOAD_BUSINESS_DOCUMENT_SUCCESS =
  'UPLOAD_BUSINESS_DOCUMENT_SUCCESS';
export const UPLOAD_BUSINESS_DOCUMENT_ERROR = 'UPLOAD_BUSINESS_DOCUMENT_ERROR';
export const DOWNLOAD_BUSINESS_DOCUMENT_START =
  'DOWNLOAD_BUSINESS_DOCUMENT_START';
export const DOWNLOAD_BUSINESS_DOCUMENT_SUCCESS =
  'DOWNLOAD_BUSINESS_DOCUMENT_SUCCESS';
export const DOWNLOAD_BUSINESS_DOCUMENT_ERROR =
  'DOWNLOAD_BUSINESS_DOCUMENT_ERROR';

// BANKS
export const FETCH_BANKS_START = 'FETCH_BANKS_START';
export const FETCH_BANKS_SUCCESS = 'FETCH_BANKS_SUCCESS';
export const FETCH_BANKS_FAILURE = 'FETCH_BANKS_FAILURE';

// DOCUMENT
export const FETCH_DOCUMENT_START = 'FETCH_DOCUMENT_START';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_ERROR = 'FETCH_DOCUMENT_ERROR';
export const UPLOAD_DOCUMENT_START = 'UPLOAD_DOCUMENT_START';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_ERROR = 'UPLOAD_DOCUMENT_ERROR';
export const DOWNLOAD_DOCUMENT_START = 'DOWNLOAD_DOCUMENT_START';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';
export const DOWNLOAD_DOCUMENT_ERROR = 'DOWNLOAD_DOCUMENT_ERROR';
export const VERIFY_DOCUMENTS_START = 'VERIFY_DOCUMENTS_START';
export const VERIFY_DOCUMENTS_SUCCESS = 'VERIFY_DOCUMENTS_SUCCESS';
export const VERIFY_DOCUMENTS_FAILURE = 'VERIFY_DOCUMENTS_FAILURE';
export const VERIFY_DOCUMENTS_RESET = 'VERIFY_DOCUMENTS_RESET';
export const FETCH_DOCUMENT_BASE64_START = 'FETCH_DOCUMENT_BASE64_START';
export const FETCH_DOCUMENT_BASE64_SUCCESS = 'FETCH_DOCUMENT_BASE64_SUCCESS';
export const FETCH_DOCUMENT_BASE64_FAILURE = 'FETCH_DOCUMENT_BASE64_FAILURE';

// GEO
export const FETCH_GEO_MX_STATES_START = 'FETCH_GEO_MX_STATES_START';
export const FETCH_GEO_MX_STATES_SUCCESS = 'FETCH_GEO_MX_STATES_SUCCESS';
export const FETCH_GEO_MX_STATES_FAILURE = 'FETCH_GEO_MX_STATES_FAILURE';
export const FETCH_GEO_MX_MUNICIPALITY_START =
  'FETCH_GEO_MX_MUNICIPALITY_START';
export const FETCH_GEO_MX_MUNICIPALITY_SUCCESS =
  'FETCH_GEO_MX_MUNICIPALITY_SUCCESS';
export const FETCH_GEO_MX_MUNICIPALITY_FAILURE =
  'FETCH_GEO_MX_MUNICIPALITY_FAILURE';
export const FETCH_GEO_MX_COLONY_START = 'FETCH_GEO_MX_COLONY_START';
export const FETCH_GEO_MX_COLONY_SUCCESS = 'FETCH_GEO_MX_COLONY_SUCCESS';
export const FETCH_GEO_MX_COLONY_FAILURE = 'FETCH_GEO_MX_COLONY_FAILURE';

// DEBT SERVICE
export const FETCH_DEBT_SERVICE_START = 'FETCH_DEBT_SERVICE_START';
export const FETCH_DEBT_SERVICE_SUCCESS = 'FETCH_DEBT_SERVICE_SUCCESS';
export const FETCH_DEBT_SERVICE_FAILURE = 'FETCH_DEBT_SERVICE_FAILURE';

// PAYMENTS
export const SUPPLIERS_SET_ACTIVE_TAB = 'SUPPLIERS_SET_ACTIVE_TAB';

// BANK ACCOUNTS
export const CREATE_BANK_ACCOUNT_FAILURE = 'CREATE_BANK_ACCOUNT_FAILURE';
export const CREATE_BANK_ACCOUNT_START = 'CREATE_BANK_ACCOUNT_START';
export const CREATE_BANK_ACCOUNT_SUCCESS = 'CREATE_BANK_ACCOUNT_SUCCESS';
export const FETCH_BANK_ACCOUNTS_FAILURE = 'FETCH_BANK_ACCOUNTS_FAILURE';
export const FETCH_BANK_ACCOUNTS_START = 'FETCH_BANK_ACCOUNTS_START';
export const FETCH_BANK_ACCOUNTS_SUCCESS = 'FETCH_BANK_ACCOUNTS_SUCCESS';
export const GENERIC_BANK_ACCOUNT_RESET = 'GENERIC_BANK_ACCOUNT_RESET';
export const GENERIC_DELETE_BANK_ACCOUNT_FAILURE =
  'GENERIC_DELETE_BANK_ACCOUNT_FAILURE';
export const GENERIC_DELETE_BANK_ACCOUNT_START =
  'GENERIC_DELETE_BANK_ACCOUNT_START';
export const GENERIC_DELETE_BANK_ACCOUNT_SUCCESS =
  'GENERIC_DELETE_BANK_ACCOUNT_SUCCESS';
export const GENERIC_FETCH_BANKS_FAILURE = 'GENERIC_FETCH_BANKS_FAILURE';
export const GENERIC_FETCH_BANKS_START = 'GENERIC_FETCH_BANKS_START';
export const GENERIC_FETCH_BANKS_SUCCESS = 'GENERIC_FETCH_BANKS_SUCCESS';
export const GENERIC_SET_DEFAULT_BANK_ACCOUNT_FAILURE =
  'GENERIC_SET_DEFAULT_BANK_ACCOUNT_FAILURE';
export const GENERIC_SET_DEFAULT_BANK_ACCOUNT_START =
  'GENERIC_SET_DEFAULT_BANK_ACCOUNT_START';
export const GENERIC_SET_DEFAULT_BANK_ACCOUNT_SUCCESS =
  'GENERIC_SET_DEFAULT_BANK_ACCOUNT_SUCCESS';
export const UPDATE_BANK_ACCOUNT_FAILURE = 'UPDATE_BANK_ACCOUNT_FAILURE';
export const UPDATE_BANK_ACCOUNT_START = 'UPDATE_BANK_ACCOUNT_START';
export const UPDATE_BANK_ACCOUNT_SUCCESS = 'UPDATE_BANK_ACCOUNT_SUCCESS';
export const BANK_ACCOUNT_CLEANUP = 'BANK_ACCOUNT_CLEANUP';
export const SET_BANK_ACCOUNT_SOURCE = 'SET_BANK_ACCOUNT_SOURCE';

// PAYROLL
export const CREATE_PAYROLL_FAILURE = 'CREATE_PAYROLL_FAILURE';
export const CREATE_PAYROLL_START = 'CREATE_PAYROLL_START';
export const CREATE_PAYROLL_SUCCESS = 'CREATE_PAYROLL_SUCCESS';
export const DELETE_PAYROLL_FAILURE = 'DELETE_PAYROLL_FAILURE';
export const DELETE_PAYROLL_START = 'DELETE_PAYROLL_START';
export const DELETE_PAYROLL_SUCCESS = 'DELETE_PAYROLL_SUCCESS';
export const FETCH_PAYROLL_FAILURE = 'FETCH_PAYROLL_FAILURE';
export const FETCH_PAYROLL_START = 'FETCH_PAYROLL_START';
export const FETCH_PAYROLL_SUCCESS = 'FETCH_PAYROLL_SUCCESS';
export const FETCH_PAYROLLS_FAILURE = 'FETCH_PAYROLLS_FAILURE';
export const FETCH_PAYROLLS_START = 'FETCH_PAYROLLS_START';
export const FETCH_PAYROLLS_SUCCESS = 'FETCH_PAYROLLS_SUCCESS';
export const UPDATE_PAYROLL_FAILURE = 'UPDATE_PAYROLL_FAILURE';
export const UPDATE_PAYROLL_START = 'UPDATE_PAYROLL_START';
export const UPDATE_PAYROLL_SUCCESS = 'UPDATE_PAYROLL_SUCCESS';
export const RESET_PAYROLLS = 'RESET_PAYROLLS';
export const PAY_PAYROLL_START = 'PAY_PAYROLL_START';
export const PAY_PAYROLL_SUCCESS = 'PAY_PAYROLL_SUCCESS';
export const PAY_PAYROLL_FAILURE = 'PAY_PAYROLL_FAILURE';
export const FINANCING_PAYROLL_START = 'FINANCING_PAYROLL_START';
export const FINANCING_PAYROLL_SUCCESS = 'FINANCING_PAYROLL_SUCCESS';
export const FINANCING_PAYROLL_FAILURE = 'FINANCING_PAYROLL_FAILURE';
export const RESET_FINANCING = 'RESET_FINANCING';

// CONTACT DETAIL
export const CONTACT_DETAIL_CLEANUP = 'CONTACT_DETAIL_CLEANUP';
export const CONTACT_DETAIL_RESET = 'CONTACT_DETAIL_RESET';
export const CREATE_CONTACT_DETAIL_FAILURE = 'CREATE_CONTACT_DETAIL_FAILURE';
export const CREATE_CONTACT_DETAIL_START = 'CREATE_CONTACT_DETAIL_START';
export const CREATE_CONTACT_DETAIL_SUCCESS = 'CREATE_CONTACT_DETAIL_SUCCESS';
export const DELETE_CONTACT_DETAIL_FAILURE = 'DELETE_CONTACT_DETAIL_FAILURE';
export const DELETE_CONTACT_DETAIL_START = 'DELETE_CONTACT_DETAIL_START';
export const DELETE_CONTACT_DETAIL_SUCCESS = 'DELETE_CONTACT_DETAIL_SUCCESS';
export const FETCH_CONTACT_DETAIL_FAILURE = 'FETCH_CONTACT_DETAIL_FAILURE';
export const FETCH_CONTACT_DETAIL_START = 'FETCH_CONTACT_DETAIL_START';
export const FETCH_CONTACT_DETAIL_SUCCESS = 'FETCH_CONTACT_DETAIL_SUCCESS';
export const UPDATE_CONTACT_DETAIL_FAILURE = 'UPDATE_CONTACT_DETAIL_FAILURE';
export const UPDATE_CONTACT_DETAIL_START = 'UPDATE_CONTACT_DETAIL_START';
export const UPDATE_CONTACT_DETAIL_SUCCESS = 'UPDATE_CONTACT_DETAIL_SUCCESS';

// PAYMENT DOCUMENTS
export const UPLOAD_VOUCHER_START = 'UPLOAD_VOUCHER_START';
export const UPLOAD_VOUCHER_SUCCESS = 'UPLOAD_VOUCHER_SUCCESS';
export const UPLOAD_VOUCHER_FAILURE = 'UPLOAD_VOUCHER_FAILURE';

export const LOAD_DOCUMENT_START = 'LOAD_DOCUMENT_START';
export const LOAD_DOCUMENT_SUCCESS = 'LOAD_DOCUMENT_SUCCESS';
export const LOAD_DOCUMENT_FAILURE = 'LOAD_DOCUMENT_FAILURE';

// ONBOARD
export const FETCH_ONBOARD_SUPPLIERS_START = 'FETCH_ONBOARD_SUPPLIERS_START';
export const FETCH_ONBOARD_SUPPLIERS_SUCCESS =
  'FETCH_ONBOARD_SUPPLIERS_SUCCESS';
export const FETCH_ONBOARD_SUPPLIERS_FAILURE =
  'FETCH_ONBOARD_SUPPLIERS_FAILURE';

export const SAVE_ONBOARD_START = 'SAVE_ONBOARD_START';
export const SAVE_ONBOARD_SUCCESS = 'SAVE_ONBOARD_SUCCESS';
export const SAVE_ONBOARD_FAILURE = 'SAVE_ONBOARD_FAILURE';
export const RESET_ONBOARD = 'RESET_ONBOARD';

// PFX
export const REGISTER_PFX_START = 'REGISTER_PFX_START';
export const REGISTER_PFX_SUCCESS = 'REGISTER_PFX_SUCCESS';
export const REGISTER_PFX_FAILURE = 'REGISTER_PFX_FAILURE';
export const CHECK_PFX_START = 'CHECK_PFX_START';
export const CHECK_PFX_SUCCESS = 'CHECK_PFX_SUCCESS';
export const CHECK_PFX_FAILURE = 'CHECK_PFX_FAILURE';

// CREDIT LINE
export const FETCH_CREDIT_LINE_START = 'FETCH_CREDIT_LINE_START';
export const FETCH_CREDIT_LINE_SUCCESS = 'FETCH_CREDIT_LINE_SUCCESS';
export const FETCH_CREDIT_LINE_FAILURE = 'FETCH_CREDIT_LINE_FAILURE';
export const SET_CREDIT_LINE_REQUEST_SENT = 'SET_CREDIT_LINE_REQUEST_SENT';
export const SET_CREDIT_LINE_ACTIVE = 'SET_CREDIT_LINE_ACTIVE';
export const SET_CREDIT_LINE_BLOCKED = 'SET_CREDIT_LINE_BLOCKED';

// WALLET
export const FETCH_WALLET_STATUS_START = 'FETCH_WALLET_STATUS_START';
export const FETCH_WALLET_STATUS_SUCCESS = 'FETCH_WALLET_STATUS_SUCCESS';
export const FETCH_WALLET_STATUS_ERROR = 'FETCH_WALLET_STATUS_ERROR';
export const CREATE_WALLET_REQUEST_START = 'CREATE_WALLET_REQUEST_START';
export const CREATE_WALLET_REQUEST_SUCCESS = 'CREATE_WALLET_REQUEST_SUCCESS';
export const CREATE_WALLET_REQUEST_ERROR = 'CREATE_WALLET_REQUEST_ERROR';

// IMPERSONATE
export const FETCH_IMPERSONATE_TOKEN_START = 'FETCH_IMPERSONATE_TOKEN_START';
export const FETCH_IMPERSONATE_TOKEN_ERROR = 'FETCH_IMPERSONATE_TOKEN_ERROR';
export const FETCH_IMPERSONATE_TOKEN_SUCCESS =
  'FETCH_IMPERSONATE_TOKEN_SUCCESS';
