import flagsmith from 'flagsmith/isomorphic';
import { urls } from '../../libs/urls';
import { RouteRequirementsUseCase } from '../domain/RouteRequirementsUseCase';

export const registerRequirementsUseCase: RouteRequirementsUseCase = (
  rawUrl,
  options
) => {
  const { user } = options;
  const userStatus = user.status;

  const unauthenticatedUrls = new Set([
    urls.login,
    urls.register,
    urls.forgotPassword,
    urls.authLogin,
    urls.authRegister,
    '/start/register',
    '/start/login',
  ]);

  const whiteList = new Set(['/legal-representatives/register']);

  let url = rawUrl.split('?')[0];
  if (url[url.length - 1] === '/') url = url.slice(0, url.length - 1);

  if (whiteList.has(url)) return null;

  if (!userStatus.isAuthenticated) {
    if (unauthenticatedUrls.has(url) || url.includes(urls.impersonate))
      return null;
    return urls.login;
  }
  if (!userStatus.isCompleteUserRegistered) {
    if (url === urls.completeRegister) return null;
    return urls.completeRegister;
  }
  if (!userStatus.isBusinessRegistered) {
    if (url === urls.businessRegister) return null;
    return urls.businessRegister;
  }
  if (userStatus.isAuthenticated && unauthenticatedUrls.has(url)) {
    if (flagsmith.hasFeature('saas_redirect_home')) return urls.home;
    if (flagsmith.hasFeature('redirect_home_finance'))
      return urls.directFinance;
    return urls.payments;
  }
  return null;
};
