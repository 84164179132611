interface UseSuppliersTemplateDownloadParams {
  countryId: string;
  templatesByCountry: {
    CL: { csv: string; xlsx: string };
    MX: { csv: string; xlsx: string };
  };
}

interface UseSuppliersTemplateDownloadResponse {
  onTemplateDownload: (type: SuppliersTemplateType) => void;
}

type SuppliersTemplateType = 'xlsx' | 'csv';

const useSuppliersTemplateDownload = ({
  countryId,
  templatesByCountry,
}: UseSuppliersTemplateDownloadParams): UseSuppliersTemplateDownloadResponse => {
  const onTemplateDownload = (type: SuppliersTemplateType) => {
    const link = document.createElement('a');
    link.download = `carga-masiva.csv`;
    link.href = `${templatesByCountry[countryId][type]}?t=${Date.now()}`;
    link.click();
  };

  return {
    onTemplateDownload,
  };
};

export { useSuppliersTemplateDownload };
