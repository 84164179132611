import { ResourceElement } from '../../types';
import auth from './auth.json';
import global from './global.json';

const enResources: ResourceElement = {
  auth,
  global,
};

export default enResources;
