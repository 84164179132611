import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducer';

interface UseBusinessResponse {
  countryId: string;
  businessId: number;
  identifier: number;
}

const useBusiness = (): UseBusinessResponse => {
  const {
    id: businessId,
    countryId,
    identifier,
  } = useSelector((state: RootState) => state.businessState.business);

  return {
    businessId,
    countryId,
    identifier,
  };
};

export { useBusiness };
