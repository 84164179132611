import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const getFirstBusinessDayFrom = (startDate, holidays = []) => {
  if (!startDate) return startDate;

  const dateToEvaluate = moment(startDate);
  const isHoliday = holidays.some(h =>
    dateToEvaluate.isSame(moment(h.date), 'day')
  );
  if (
    dateToEvaluate.isoWeekday() !== 6 &&
    dateToEvaluate.isoWeekday() !== 7 &&
    !isHoliday
  ) {
    return startDate;
  }
  return getFirstBusinessDayFrom(
    dateToEvaluate.add(1, 'day').format('YYYY-MM-DD')
  );
};

export const isWeekend = date => {
  const dayOfWeek = date.format('E');
  return dayOfWeek === '6' || dayOfWeek === '7';
};

export const getWeekendDays = (now, businessDays) => {
  return new Array(businessDays)
    .fill(now)
    .map((date, index) => {
      const newDate = date.clone();
      return newDate.add(index, 'days');
    })
    .reduce((acc, date) => {
      if (isWeekend(date)) {
        return acc + 1;
      }
      return acc;
    }, 0);
};

export const blockBusinessDays = (date, days) => {
  const businessDays = days;
  const now = moment().startOf('day');
  const weekendDays = getWeekendDays(now, businessDays);
  // only take in account one weekends
  const offset = Math.ceil(weekendDays / 2) * 2;

  const daysDiff = date.diff(now, 'days');
  return daysDiff < businessDays + offset;
};

export const dateWithoutFormatter = date => {
  return date ? moment(date).utc() : null;
};

export const isTodayDate = date => {
  return moment.utc(date).isSame(moment.utc(), 'day');
};

export const formatToReadableDate = (date, readableDateFormat) => {
  const readableDate = moment.utc(date).format(readableDateFormat);
  return isTodayDate(date) ? `Hoy, ${readableDate}` : readableDate;
};
