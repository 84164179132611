import { Resources } from '../types';
import esResources from './es';
import enResources from './en';

const resources: Resources = {
  es: esResources,
  en: enResources,
};

export default resources;
