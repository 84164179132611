import { PURGE, REHYDRATE } from 'redux-persist';
import {
  FETCH_CREDIT_LINE_START,
  FETCH_CREDIT_LINE_SUCCESS,
  FETCH_CREDIT_LINE_FAILURE,
  LOCAL_PURGE,
  SET_CREDIT_LINE_REQUEST_SENT,
  SET_CREDIT_LINE_ACTIVE,
  SET_CREDIT_LINE_BLOCKED,
} from '../actions/types';

import { t13s } from '../../config/translationKeys';

const initialState = {
  creditLine: {},

  isCreditLineRequestSent: false,
  isCreditLineActive: false,

  creditLineLoading: false,
  creditLineFailure: null,
};

const setCreditLineRequestSent = state => {
  return {
    ...state,
    isCreditLineRequestSent: true,
  };
};

const setCreditLineActive = (state, payload) => {
  const { isCreditLineActive } = payload;

  return {
    ...state,
    isCreditLineActive,
  };
};

const setCreditLineBlocked = (state, payload) => {
  const { isCreditLineBlocked } = payload;

  return {
    ...state,
    isCreditLineBlocked,
  };
};

const fetchCreditLineStart = state => {
  return {
    ...state,
    creditLine: {},
    creditLineLoading: true,
    creditLineFailure: null,
  };
};

const fetchCreditLineSuccess = (state, payload) => {
  const { creditLine } = payload;
  return {
    ...state,
    creditLine,
    creditLineLoading: false,
  };
};

const fetchCreditLineFailure = (state, payload) => {
  const { errorCode } = payload;
  return {
    ...state,
    creditLineLoading: false,
    creditLineFailure: errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const rehydrate = payload => {
  return {
    ...initialState,
    creditLine: payload?.creditLineState?.creditLine || {},
    isCreditLineRequestSent:
      payload?.creditLineState?.isCreditLineRequestSent || false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CREDIT_LINE_START:
      return fetchCreditLineStart(state);
    case FETCH_CREDIT_LINE_SUCCESS:
      return fetchCreditLineSuccess(state, payload);
    case FETCH_CREDIT_LINE_FAILURE:
      return fetchCreditLineFailure(state, payload);

    case SET_CREDIT_LINE_REQUEST_SENT:
      return setCreditLineRequestSent(state);
    case SET_CREDIT_LINE_ACTIVE:
      return setCreditLineActive(state, payload);
    case SET_CREDIT_LINE_BLOCKED:
      return setCreditLineBlocked(state, payload);

    case PURGE:
      return initialState;
    case LOCAL_PURGE:
      return initialState;
    case REHYDRATE:
      return rehydrate(payload);

    default:
      return state;
  }
};
