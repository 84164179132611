import { ResourceElement } from '../../types';
import auth from './auth.json';
import global from './global.json';

const esResources: ResourceElement = {
  auth,
  global,
};

export default esResources;
