import { REHYDRATE } from 'redux-persist';
import { SUPPLIERS_SET_ACTIVE_TAB, LOCAL_PURGE } from '../actions/types';
import { PAYMENT_SUPPLIER_TABS } from '../../helpers/constants';

const initialState = {
  activeTab: PAYMENT_SUPPLIER_TABS.INFO,
  suppliers: [],
  pagination: { totalData: 0, pageSize: 20, page: 1 },
  supplier: null,

  suppliersIsLoading: false,
  suppliersError: null,

  supplierUpdateIsLoading: false,
  supplierUpdated: false,
  supplierUpdateError: null,
};

const setActiveTab = (state, payload) => {
  return {
    ...state,
    activeTab: payload,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SUPPLIERS_SET_ACTIVE_TAB:
      return setActiveTab(state, payload);
    case LOCAL_PURGE:
      return initialState;
    case REHYDRATE: {
      return initialState;
    }
    default:
      return state;
  }
};
